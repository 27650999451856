<template>
  <div>
    <div class="container-title mt-4">
      <div>PLAN DE MALETA DIDÁCTICA</div>
      <div>DESCRIPCIÓN DE ASIGNATURA</div>
    </div>
    <!-- Primera Tabla -->
    <b-table-simple
      v-if="description_matter"
      bordered
      responsive
      class="container-table-header mt-3 mb-4"
    >
      <!--  Fila 1 -->
      <b-tr>
        <b-td class="primary-cell">Unidad Acádemica</b-td>
        <b-td class="secundary-cell">{{ description_matter.faculty }} </b-td>
        <b-td class="primary-cell">Nombre Asignatura</b-td>
        <b-td class="secundary-cell">{{ description_matter.matter_name }}</b-td>
        <b-td class="primary-cell">Créditos DUOC</b-td>
        <td class="secundary-cell">
          <div>{{ description_matter.duoc_credits }}</div>
        </td>
        <b-td class="primary-cell">Prerequisitos</b-td>
        <b-td class="secundary-cell">
          {{ description_matter.prerequisites.join(", ") }}
        </b-td>
      </b-tr>

      <!-- Fila 2 -->
      <b-tr>
        <b-td class="primary-cell">
          <div class="container-career-name">
            <span>Nombre Carrera/s</span>
            <span>Técnica/Profesional</span>
          </div>
        </b-td>
        <b-td class="secundary-cell">{{
          description_matter.career_names.join(", ")
        }}</b-td>
        <b-td rowspan="2" class="primary-cell">Sigla</b-td>
        <b-td rowspan="2" class="secundary-cell">{{
          description_matter.matter_code
        }}</b-td>
        <b-td class="primary-cell"
          >Horas Docencia Dirigida Semestre (Pedagógicas)</b-td
        >
        <b-td class="secundary-cell p-0">{{
          description_matter.directed_teaching_hours
        }}</b-td>
        <b-td class="primary-cell">N° Docentes</b-td>
        <b-td class="secundary-cell">
          <template v-if="description_matter.suggested_teachers">
            <div>{{ description_matter.suggested_teachers }}</div>
          </template>
          <template v-else>
            <div>No Aplica</div>
          </template>
        </b-td>
      </b-tr>

      <!-- Fila 3 -->
      <b-tr>
        <b-td class="primary-cell"> Planes de Estudio </b-td>
        <b-td class="secundary-cell">{{
          description_matter.study_plans.join(", ")
        }}</b-td>
        <b-td class="primary-cell">
          Hrs. Docencia Dirigida Presencial / Docencia Dirigida No Presencial
        </b-td>
        <b-td class="divided-cell">
          <div class="content-left">
            {{ description_matter.in_person_directed_teaching_hours }}
          </div>
          <div class="content-right">
            0
            <!-- {{ description_matter.online_directed_teaching_hours }} -->
          </div>
        </b-td>
        <b-td class="primary-cell">N° Ayudantes</b-td>
        <b-td class="secundary-cell">
          <template v-if="description_matter.suggested_assistants">
            <div>{{ description_matter.suggested_assistants }}</div>
          </template>
          <template v-else>
            <div>No Aplica</div>
          </template>
        </b-td>
      </b-tr>

      <!--  Fila 4 -->
      <b-tr>
        <b-td class="primary-cell">{{
          description_matter.referenced_matters_title
        }}</b-td>
        <b-td class="secundary-cell">
          <div
            class="rich-text-content"
            v-html="description_matter.referenced_matters"
          ></div
        ></b-td>
        <b-td class="primary-cell">Línea Formativa</b-td>
        <b-td class="secundary-cell">{{
          description_matter.formation_line
        }}</b-td>
        <b-td class="primary-cell">
          Créditos Académicos Transferibles (SCT)
        </b-td>
        <b-td class="secundary-cell p-0">{{
          description_matter.sct_credits_again
        }}</b-td>
        <b-td class="primary-cell">N° Horas Ayudantías</b-td>
        <b-td class="secundary-cell">{{
          description_matter.assistantship_modules
        }}</b-td>
      </b-tr>

      <!--  Fila 5 -->
      <b-tr>
        <b-td class="primary-cell"
          >Formato Asignatura (Presencial, FOL, DUAL)
        </b-td>
        <b-td class="secundary-cell">{{
          description_matter.modalities.join(", ")
        }}</b-td>
        <b-td class="primary-cell">N° de Semanas de Programación</b-td>
        <b-td class="secundary-cell">{{ description_matter.weeks }}</b-td>
        <b-td class="primary-cell"> Horas de Trabajo Autónomo </b-td>
        <b-td class="secundary-cell p-0">{{
          description_matter.autonomous_work_hours
        }}</b-td>
        <b-td class="primary-cell">Fecha de Publicación</b-td>
        <b-td class="secundary-cell">
          {{ description_matter.publish_date | FormatToDate }}
        </b-td>
      </b-tr>
    </b-table-simple>

    <!-- Segunda Tabla -->
    <b-table-simple
      v-if="description_matter"
      bordered
      responsive
      outlined
      class="container-table2 mb-4"
    >
      <!-- Fila 1 -->
      <b-tr>
        <b-td
          :rowspan="4 + description_matter.study_environments.length"
          class="primary-cell-table-2 text-center"
          >{{ description_matter.matter_description_title }}</b-td
        >
        <b-td
          colspan="2"
          class="secundary-cell-table-2 description-matter-table2"
        >
          <div
            v-html="description_matter.matter_description"
            class="rich-text-content"
          ></div>
        </b-td>
      </b-tr>
      <!-- Fila 2 -->
      <b-tr>
        <b-td class="primary-cell-table-2">Estrategias metodológicas</b-td>
        <b-td colspan="2" class="secundary-cell-table-2 text-left">
          <div
            v-for="(
              methodological_strategie, index
            ) in description_matter.methodological_strategies"
            :key="'methodological_strategie:' + index"
            class="rich-text-content"
            v-html="methodological_strategie"
          ></div>
        </b-td>
      </b-tr>

      <!-- Fila 3 -->
      <b-tr>
        <b-td rowspan="1" class="primary-cell-table-2"
          >Ambiente(s) de Aprendizaje Docencia Dirigida Presencial</b-td
        >
        <b-td rowspan="1" class="secundary-cell-table-2 align-middle text-left">
          <div>
            {{ description_matter.study_environments.join(", ") }}
          </div>
        </b-td>
        <!-- <b-td class="primary-cell-table-2"
          >Si selecciona Laboratorio o Taller de Especialidad,
          específicar:</b-td
        >
        <b-td
          class="secundary-cell-table-2 text-left"
          style="width: 25%"
        ></b-td> -->
      </b-tr>

      <!-- Fila 4 -->
      <b-tr>
        <b-td class="primary-cell-table-2"
          >Ambiente(s) de Aprendizaje Docencia Dirigida No Presencial</b-td
        >
        <b-td class="secundary-cell-table-2 text-left"></b-td>
        <!-- <b-td colspan="2" class="secundary-cell-table-2"></b-td> -->
      </b-tr>

      <!-- Fila 5 -->
      <b-tr>
        <b-td class="primary-cell-table-2"
          >Evaluacion Final Transversal (Situación evaluativa y
          descripción)</b-td
        >
        <b-td colspan="2" class="secundary-cell-table-2">
          <div class="d-flex">
            <div
              v-for="(
                transversal, index
              ) in description_matter.transversal_exam_data"
              :key="'transversal' + index"
              class="d-flex"
            >
              <template v-if="index > 0">,&nbsp;</template>
              <div class="rich-text-content" v-html="transversal"></div>
            </div>
          </div>
        </b-td>
      </b-tr>
    </b-table-simple>

    <!-- Tercera Tabla -->
    <b-table-simple
      v-if="description_matter"
      bordered
      responsive
      outlined
      class="container-table3 mb-5"
    >
      <b-thead>
        <!-- Fila 1 -->
        <b-tr>
          <b-td colspan="3" class="primary-cell-table-3">MAPA FORMATIVO</b-td>
          <b-td colspan="1" class="primary-cell-table-3">
            DESARROLLO INSTRUCCIONAL PLAN MALETA DIDÁCTICA</b-td
          >
        </b-tr>
        <!-- Fila 2 -->
        <b-tr>
          <b-td style="width: 10%" class="primary-cell-table-3"
            >CAPACIDADES INSTITUCIONALES</b-td
          >
          <b-td style="width: 20%" class="primary-cell-table-3"
            >CAPACIDAD/ES Y COMPETENCIA/S DEL PEFRIL DE EGRESO</b-td
          >
          <b-td style="width: 20%" class="primary-cell-table-3"
            >RESULTADOS DE APRENDIZAJE</b-td
          >
          <b-td style="width: 50%" class="primary-cell-table-3"
            >INDICADORES DE LOGRO</b-td
          >
        </b-tr>
      </b-thead>

      <b-tbody>
        <template v-if="!description_matter">
          <b-tr>
            <b-td colspan="4"> No hay datos para mostrar. </b-td>
          </b-tr>
        </template>

        <!-- Template cuando no hay Competencias, Resultados de Aprendizaje ni Indicadodores de Logro -->
        <template v-if="description_matter.competence_dicts == 0">
          <b-tr>
            <!-- No hay Info -->
            <b-td colspan="4" class="align-middle">No hay información </b-td>
          </b-tr>
        </template>

        <!-- Template caso Normal recorriendo Competencias -->
        <template
          v-for="(
            competence_dict, index1
          ) in description_matter.competence_dicts"
        >
          <!-- Template cuando no hay Resultados de Aprendizaje ni Indicadodores de Logro -->
          <template v-if="competence_dict.study_units == 0">
            <b-tr :key="`competence1-${competence_dict.id}-${index1}`">
              <!-- Capacidades Institucionales -->
              <b-td
                class="align-middle"
                v-if="index1 == 0"
                :rowspan="getRowCapacities(description_matter.competence_dicts)"
              >
                <template>
                  <div
                    v-for="(capacity, index) in description_matter.capacities"
                    :key="'capacity1' + index"
                  >
                    {{ capacity }}
                  </div>
                </template>
              </b-td>

              <!-- Capacidades y Competencias -->
              <b-td class="align-middle"
                >{{ competence_dict.full_sentence }}
              </b-td>

              <!-- Resultados de Aprendizaje -->
              <b-td class="align-middle">
                No hay Resultados de Aprendizaje
              </b-td>

              <!-- Indicadores de Logro -->
              <b-td class="align-middle"> No hay Indicadores de Logro </b-td>
            </b-tr>
          </template>

          <!-- Template caso Normal recorriendo RA -->
          <template v-for="(study_unit, index2) in competence_dict.study_units">
            <!-- Template cuando no hay Indicadodres de Logro -->
            <template v-if="study_unit.evaluation_criterias_micro == 0">
              <b-tr
                :key="`competence0-${competence_dict.id}-${index1}-unit0-${study_unit.id}-${index2}-criteria`"
              >
                <!-- Capacidades Institucionales -->
                <b-td
                  class="align-middle"
                  v-if="index1 == 0 && index2 == 0"
                  :rowspan="
                    getRowCapacities(description_matter.competence_dicts)
                  "
                >
                  <template>
                    <div
                      v-for="(capacity, index) in description_matter.capacities"
                      :key="'capacity0' + index"
                    >
                      {{ capacity }}
                    </div>
                  </template>
                </b-td>

                <!-- Capacidades y Competencias -->
                <b-td
                  v-if="index2 == 0"
                  :rowspan="getRowCompetence(competence_dict.study_units)"
                  class="align-middle"
                  >{{ competence_dict.full_sentence }}
                </b-td>

                <!-- Resultados de Aprendizaje -->
                <b-td class="align-middle">
                  {{ study_unit.full_sentence }}
                </b-td>

                <!-- Indicadores de Logro -->
                <b-td class="align-middle"> No hay Indicadores de Logro </b-td>
              </b-tr>
            </template>

            <!-- Template caso Normal recorriendo IL -->
            <template
              v-for="(
                evaluation_criteria_micro, index3
              ) in study_unit.evaluation_criterias_micro"
            >
              <b-tr
                :key="`competence-${competence_dict.id}-${index1}-unit-${study_unit.id}-${index2}-criteria-${evaluation_criteria_micro.id}-${index3}`"
              >
                <!-- Capacidades Institucionales -->
                <b-td
                  class="align-middle"
                  v-if="index1 == 0 && index2 == 0 && index3 == 0"
                  :rowspan="
                    getRowCapacities(description_matter.competence_dicts)
                  "
                >
                  <template>
                    <div
                      v-for="(capacity, index) in description_matter.capacities"
                      :key="'capacity' + index"
                    >
                      {{ capacity }}
                    </div>
                  </template>
                </b-td>

                <!-- Capacidades y Competencias -->

                <b-td
                  v-if="index2 == 0 && index3 == 0"
                  :rowspan="getRowCompetence(competence_dict.study_units)"
                  class="align-middle"
                >
                  <div>
                    {{ competence_dict.full_sentence }}
                  </div>
                </b-td>

                <!-- Resultados de Aprendizaje -->
                <b-td
                  v-if="index3 == 0"
                  class="align-middle"
                  :rowspan="study_unit.evaluation_criterias_micro.length"
                >
                  <div>
                    <!-- <b-badge
                      pill
                      variant="none"
                      class="ra-badge mr-1 noprint"
                      v-b-tooltip.v-secondary.noninteractive.bottom="
                        study_unit.full_sentence
                      "
                    >
                      RA{{ study_unit.order }}
                    </b-badge> -->
                    <!-- <p class="learning-result"> -->
                    {{ study_unit.full_sentence }}
                    <!-- </p> -->
                  </div>
                </b-td>

                <!-- Indicadores de Logro -->
                <b-td class="align-middle">
                  <div>
                    {{ evaluation_criteria_micro.full_sentence }}
                  </div>
                </b-td>
              </b-tr>
            </template>
          </template>
        </template>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import * as names from "@/store/names";
// import { mapGetters } from "vuex";

export default {
  name: "LearningMatterDescription",
  props: {},
  data() {
    return {
      description_matter: null,
      egress_profile_matter_id: Number(
        this.$route.params.egress_profile_matter_id
      ),
    };
  },
  computed: {
    // ...mapGetters({}),
  },
  methods: {
    getRowCompetence(study_units) {
      let row = 0;
      study_units.forEach((x) => {
        if (x.evaluation_criterias_micro.length == 0) row++;
        row += x.evaluation_criterias_micro.length;
      });
      return row;
    },
    getRowCapacities(competence_dicts) {
      let row = 0;
      competence_dicts.forEach((y) => {
        if (y.study_units.length == 0) row++;
        y.study_units.forEach((x) => {
          if (x.evaluation_criterias_micro.length == 0) row++;
          row += x.evaluation_criterias_micro.length;
        });
      });
      return row;
    },
    getDescriptionMatter() {
      this.$restful
        .Get(
          `/teaching/learning_suitcase_page_one_web/?egress_profile_matter=${this.egress_profile_matter_id}`
        )
        .then((response) => {
          this.description_matter = response;
        });
    },
  },
  watch: {},
  mounted() {
    this.getDescriptionMatter();
  },
  beforeDestroy() {
    this.$store.commit(names.RESET_TITLE_OF_THE_PRINT);
  },
  created() {
    if (this.egress_profile_matter_id)
      this.$store
        .dispatch(
          names.FETCH_EGRESS_PROFILE_MATTER,
          this.egress_profile_matter_id
        )
        .then((egress_profile_matter) => {
          this.$store
            .dispatch(names.FETCH_MATTER, egress_profile_matter.matter)
            .then((matter) => {
              this.$store.commit(
                names.MUTATE_TITLE_OF_THE_PRINT,
                `Descripción de Asignatura - ${matter.code} ${matter.name}`
              );
            });
        });
  },
};
</script>

<style scoped src="@/utils/rich_text_editor.css">
</style>

<style scoped>
.container-title {
  font-weight: bold;
  font-size: var(--secondary-title-font-size);
  text-align: left;
}
.container-table-header {
  font-size: var(--secondary-font-size);
}
.container-table2 {
  font-size: var(--secondary-font-size);
}
.container-table3 {
  font-size: var(--secondary-font-size);
}
.container-table-header th,
.container-table-header td,
.container-table2 th,
.container-table2 td,
.container-table3 th,
.container-table3 td {
  border: 1px solid #000000;
}
.primary-cell {
  text-align: left;
  padding: 0.6rem;
  background-color: #d9d9d9 !important;
  width: 12.5%;
  max-width: 12.5%;
  vertical-align: middle;
  font-weight: bold;
}
.primary-cell-table-2 {
  text-align: left;
  padding: 0.6rem;
  background-color: #d9d9d9 !important;
  width: 15%;
  max-width: 15%;
  vertical-align: middle;
  font-weight: bold;
}
.primary-cell-table-3 {
  padding: 0.6rem;
  background-color: #d9d9d9 !important;
  vertical-align: middle;
  font-weight: bold;
}
.secundary-cell {
  width: 12.5%;
  max-width: 12.5%;
  vertical-align: middle;
}
.secundary-cell-table-2 {
  vertical-align: middle;
}
.secundary-cell-table-3 {
  vertical-align: middle;
}
.third-cell {
  border-right: 1px solid #2212;
}
.third-cell:last-child {
  border-right: none;
}
.container-career-name {
  display: flex;
  flex-direction: column;
}
.description-matter-table2 {
  height: 100px !important;
  vertical-align: middle !important;
}
.divided-cell {
  position: relative; /* Necesario para el posicionamiento del pseudo-elemento */
  vertical-align: middle !important;
}
.divided-cell::after {
  content: "";
  position: absolute;
  right: 50%; /* Posición para que el borde esté en el medio del td */
  top: 0;
  bottom: 0;
  border-right: 1px solid black; /* El borde que actúa como separador */
}
.content-left,
.content-right {
  width: 50%;
  display: inline-block;
  vertical-align: top; /* Para manejar contenido de diferentes alturas */
  box-sizing: border-box;
}
.content-left {
  padding-right: 10px;
}
.content-right {
  padding-left: 10px;
}
.ra-badge {
  border: 1px solid black;
  font-size: 9pt;
  letter-spacing: 0.3px;
}
/* .learning-result { //Estilos del badge de los RA
  display: none;
}
@media print {
  .learning-result {
    display: flex;
    text-align: justify;
  }
} */
</style>